.wordpress-helpdesk {}

.wordpress-helpdesk-row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.page_container_inner {
    min-height: 50vh;
}

.wordpress-helpdesk-pull-right {
    background: #fff;
    padding: 30px;

    @include medium_large {
        width: 100%!important;
    }
}

.wordpress-helpdesk-pull-left {
    background: #fff;
    width: 31%!important;
    padding: 30px;
    @include medium_large {
        width: 100%!important;
    }
}

.wordpress-helpdesk-sidebar {
    background: #fff;
    width: 31%!important;
    padding: 30px;
    @include medium_large {
        width: 100%!important;
    }
}

.wordpress-helpdesk-form {
    background: #fff;
    padding: 30px;
margin-left: 15px;
    @include medium_large {
        width: 100%!important;
margin-left: 0px;
    }
input[type=submit] {
    background: #3a4f41;
    color: #fff;
    padding: 15px 0;
    min-width: 200px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: normal !important;
    letter-spacing: 0.5px;
    text-decoration: none !important;

&:hover {
    background: #2d3e33;
        transition: 0.2s ease-in-out;
        color: #fff;
}
}
}
.helpdesk-privacy {
a {
    color: color(orange)!important;
margin-left: 5px;
}
}
.wordpress-helpdesk-faq-topic-icon {
    color: color(orange)!important;
}

.wordpress-helpdesk-faq-boxed-title {
    color: #000!important;
}

.helpdesk-content-container {
    .wordpress-helpdesk-col-sm-6 {
        .wordpress-helpdesk-faq {
            text-align: center;
            border: 1px solid #eaeaea;
            padding: 40px;
            margin-bottom: 10px;
        }

        .wordpress-helpdesk-faq-divider {
            display: none;
        }
    }
}

.wordpress-helpdesk-faq-topic-title-container {
    margin-left: 15px;
}

.wordpress-helpdesk-topic-description {
    margin-left: 15px;
}

.wordpress-helpdesk-faq-back-to-parent-topic-container {
    margin-left: 15px;
    margin-bottom: 15px;
}

.wordpress-helpdesk-faq-link {
    margin-top: 30px!important;
}

.wordpress-helpdesk-sidebar {
    .widget_faq_live_search {
        margin-bottom: 30px;
    }

    .wordpress-helpdesk-topic-list {
        margin-left: 20px!important;
    }

    .wordpress-helpdesk-faq-list {
        margin-left: 20px!important;
    }

    .widgettitle {
        margin-bottom: 5px;
        font-size: 24px;
        margin-left: 20px;
    }
}
