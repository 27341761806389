
/*Image modal*/

.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.93);
    margin-top: -1px;
    animation: zoom 0.3s ease-in-out;
    z-index: 99999;

    i {
        font-size: 36px;
        color: #fff!important;
        cursor: pointer;
    z-index: 9000000;
text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
        &:hover {
            transition: 0.2s ease-in-out;
            color: color(orange);
        }
    }

    .prevBtn {
    z-index: 9000000;
        @include medium {
            margin-right: -36px;
        }
    }
    .nextBtn {
    z-index: 9000000;
        @include medium {
            margin-left: -36px;
        }
    }
}
@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.modal img {
    height: auto;
        width: 92%;
        object-fit: cover;

@include medium_large {
height: auto;
width: 80%;
}
}

.closeBtn {

    font-size: 25px;
    position: absolute;
    top: 130px;
    right: 30px;
    margin: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        transition: 0.2s ease-in-out;
        color: color(orange);
    }
}

.closeBtn:hover {
    color: rgb(255, 255, 255);
}

.modal-img-text {
    position: absolute;
    bottom: 30px;
    color: #fff;
    background: #000;
    padding: 15px;
}
