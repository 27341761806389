/* MEDIA QUERIES */
$x_large: 2000px;
$large: 2000px;
$medium_large: 992px;
$medium: 768px;
$small: 576px;
$extra_small: 420px;

@mixin xl {
	@media (max-width: #{$x_large}) {
		@content;
	}
}
@mixin large {
	@media (max-width: #{$large}) {
		@content;
	}
}
@mixin medium_large {
	@media (max-width: #{$medium_large}) {
		@content;
	}
}
@mixin medium {
	@media (max-width: #{$medium}) {
		@content;
	}
}

@mixin small {
	@media (max-width: #{$small}) {
		@content;
	}
}

@mixin xs {
	@media (max-width: #{$extra_small}) {
		@content;
	}
}
