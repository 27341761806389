body {
	font-family: 'Montserrat', sans-serif;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100px;
}

html {
	min-height: 100px;
}
.hidden {
display: none!important;
}
/* Breadcrumbs */

#breadcrumbs {
	position: relative;
	padding-top: 0;
	margin-top: -36px;
	padding-left: 0;
	min-height: 0!important;
	display: none;

	a {
		color: color(orange);
		@include medium {
			font-size: 14px;
		}
	}
	@include medium {
		font-size: 14px;
		padding-bottom: 15px;
		margin-top: 0;
	}
}
/* Buttons */
.btn {
	padding: 15px 0;
	min-width: 200px;
	border-radius: 15px;
	font-size: 16px;
	font-weight: normal!important;
	letter-spacing: 0.5px;
	text-decoration: none!important;

	&:hover {
		text-decoration: none;
	}
}

.btn-yellow {
	background: color(yellow);
	color: #000!important;

	&:hover {
		background: #e9b630;
		transition: 0.2s ease-in-out;
	}
}

.btn-green {
	background: color(dark_green);
	color: color(light);

	a {
		color: color(light);
		text-decoration: none;
	}

	&:hover {
		background: #2d3e33;
		transition: 0.2s ease-in-out;
		color: color(light);
		text-decoration: none;
	}
}

.hide {
	opacity: 0;
	height: 1px;
	margin-left: 0!important;
	width: 1px;
	float: left;
}
/* Sections */
section {
	padding: 60px 0;
	@include medium {
		padding: 30px 0;
	}
}

h2 {
	margin-bottom: 60px;
	margin-left: 0;
	padding-left: 0;
	@include medium {
		margin-bottom: 20px;
	}
}

.ref-block {
	background: color(light);
	padding: 15px;
	margin-bottom: 30px;
	max-width: 31.3%;
	@include medium {
		max-width: 48.3%;
	}
	@include small {
		max-width: 100%;
	}

	img {
		width: 100%;
		height: auto;
		margin-bottom: 15px;
		height: 250px;
	    width: 100%;
	    object-fit: cover;
	    object-position: center center;

		@include xs {
			height: auto;
			width: 100%;
		}

	}
}

.drift-block {
	background: color(light);
	padding: 15px;
	margin-bottom: 30px;
	max-width: 48.3%;
	@include medium {
		max-width: 48.3%;
	}
	@include small {
		max-width: 100%;
	}

	img {
		height: 60px;
		width: auto;
		padding-bottom: 0;
		margin-bottom: 30px;
	}
}

.middle-block {
	margin-left: 3%;
	margin-right: 3%;
	@include medium {
		margin-left: 0;
		margin-right: 0;
	}
}

.full-w-block {
	@include medium {
		max-width: 100%;
	}
}

.first-block {
	@include medium {
		margin-right: 3%;
	}
	@include small {
		margin-right: 0;
	}
}
/* Startpage hero */

.startpage-hero {

	background-image: url("/wp-content/uploads/2024/03/hero2.webp");
	background-size: cover;
    background-position: 50% 0%;
	min-height: 90vh;
	@include small {
			background-image:none;
	}
	p {
		@include small {
			font-size: 21px;
			line-height: 32px;

		}
	}
	.hero-btn-first {

		@include small {
			margin-bottom: 30px!important;
		}
	}
}


/* References */
.references {
	background: color(mint);

	h2 {
		padding-left: 0;
	}

	.container {
		padding: 0 30px;
	}

	.ref-block {
		padding: 15px;

		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}
	}

	p {
		padding-top: 15px;
	}

	a {
		color: color(font);

		&:hover {
			text-decoration: none;
		}
	}

	.ref-wrapper {
		width: 100%;
		text-align: right;
		font-weight: bold;

		a {
			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
			}
		}
	}
}
/* Services */
.services {
	padding: 60px 0 30px;
	@include medium {
		padding: 30px 0 15px;
	}

	.container {
		padding: 0 30px;
	}
	background: color(light_green);

	h2 {
		padding-left: 0;
	}

	.ref-block {
		padding: 30px;

		img {
			height: 60px;
			width: auto;
			padding-bottom: 0;
			margin-bottom: 30px;
		}

		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
		min-height: 100px;
	}

	a {
		text-decoration: none;
		color: color(font);
	}

	.text-right {
		margin-top: 30px;
		font-weight: bold;
	}

	.ref-wrapper {
		width: 100%;
		text-align: right;
		margin-top: 30px;
		font-weight: bold;
	}
}

.about {
	background: color(light);
	padding: 60px 0 0px 0;
	@include medium {
		padding: 30px 0;
	}

	h2 {
		margin-bottom: 20px;
		padding-left: 0;
		@include medium {
			margin-bottom: 20px;
		}
	}

	h3 {
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 30px;
		@include medium {
			margin-bottom: 20px;
		}
	}

	.header-text-row {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	img {
		width: 60%;
		height: auto;
		@include medium {
			margin-top: 30px;
		}
	}
}

.news {
	.container {
		padding: 0 10px;
	}
	background: color(light);

	img {
		height: 300px;
		width: 100%;
		object-fit: cover;
		object-position: center center;
		@include xs {
			height: auto;
			width: 100%;
		}
	}
.ref-wrapper-row {
padding: 0 15px;
}
	.ref-block {
		padding: 0;
		border: 1px solid color(light_green);
		cursor: pointer;
		@include medium_large {
			width: 49%;
			max-width: 100%;
		}
		@include medium {
			width: 100%;
		}

		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.date {
		padding: 0 15px;
		font-size: 0.9em;
	}
.post-title {
margin-top:15px;
	padding: 0 15px;
	font-size: 16px;
    display: block;
}

	p {
		margin: 0;
			padding: 10px 15px 15px 15px;
	}

	a {
		color: color(font);
	}

	.ref-wrapper {
		width: 100%;
		text-align: right;
		font-weight: bold;

		a {
			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
			}
		}
	}
}

.contact {
	form {
		width: 100%;
		@include medium {
			margin-left: 0;
			margin-top: 25px;
		}

		h3 {
			color: color(light);
			@include medium {
				margin-left: 0;
			}
		}
	}
	background: color(grey);

	h2 {
		color: color(light);
		margin-bottom: 20px;
		@include medium {
			margin-bottom: 20px;
		}
	}

	p {
		color: color(light);
		font-size: 18px;
	}

	b {
		color: color(light);
		font-size: 18px;
		font-weight: bold;
	}

	a {
		color: color(orange);
		font-weight: bold;
		font-size: 18px;

		&:hover {
			text-decoration: underline;
			transition: 0.2s ease-in-out;
		}
	}

	.error {
		margin-top: 5px;
		color: color(orange);
		//	margin-left: 10%;
		opacity: 0;
		margin-bottom: 0;
		font-size: 0;
		padding-bottom: 15px;
	}

	input {
		display: block;
		width: 100%;
		padding: 10px;
		outline: none;
		@include medium {
			margin-left: 0;
		}
	}

	select {
		display: block;
		width: 100%;
		outline: none;
		padding: 10px;
		border: 0;
		@include medium {
			margin-left: 0;
		}
	}

	#agree-to {
		margin-top: 15px;
		text-align: left;
		width: 100%;

		#checkbox {
			width: 15px;
			display: inline-block;
			margin-left: 0;
			padding-left: 0;
			float: left;
			margin-top: 6px;
			margin-right: 15px;
		}
	}

	.required {
		font-weight: bold;
	}

	label {
		color: color(light);
	}

	textarea {
		width: 100%;
		outline: none;
		min-height: 150px;
		padding: 10px;
	}

	.submit-wrapper {
		width: 100%;
		text-align: right;

		.send-btn {
			background: color(light);
			margin-top: 15px;
			border-radius: 20px;
			cursor: pointer;

			&:hover {
				background: color(orange);
				color: color(light);
				transition: 0.2s ease-in-out;
			}
		}
	}
}
/* Cookie concent */

#cookie-consent {
	padding-top: 30px;
	padding-bottom: 15px;
	position: fixed;
	bottom: 0;
	background: color(footer);
	width: 100%;
	z-index: 999;
	min-height: 208px;

	p {
		color: color(light);
		position: relative;
	}

	#cookie-p {
		min-height: 68px;
	}

	a {
		color: color(orange);
		font-weight: bold;
	}

	.btn-yellow {
		margin-right: 30px;
		color: color(font);
	}
}

.seo-presentation {
	h2 {
		padding: 0;
		margin-bottom: 15px;
	}

	p {
		padding: 0;
		margin-bottom: 60px;
	}
}

#input-hero-text {
	display: none;
}
/* 404 page */
.page-404 {
	background: #e8eddf;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		text-align: center;
	}

	img {
		width: 60%!important;
		height: auto!important;
		margin-top: -10vh;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: 1199px) {
		img {
			width: 80%!important;
		}
	}
	@media screen and (max-width: 700px) {
		img {
			width: 90%!important;
		}
	}

	h1 {
		margin-top: 30px;
	}

	a {
		color: color(orange);
	}
}
/* For jquery editable */

.btn-save {
	background: color(dark_green);
	border-radius: 15px;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: white!important;
	border: none;
	outline: none;
	min-width: auto;
	margin: 15px 0 30px;
	min-height: auto!important;
	max-width: 100px!important;
	display: inline-block!important;
	padding: 15px!important !important;
}

.btn-cancel {
	background: color(yellow);
	border-radius: 15px;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 0.5px;
	margin-left: 30px;
	border: none;
	outline: none;
	min-width: auto;
	margin: 15px 0 30px 15px;
	min-height: auto!important;
	max-width: 100px!important;
	display: inline-block!important;
	padding: 15px!important !important;
	color: #000!important;
}

.why-trinxy-page {
	.btn-cancel {
		background: color(orange);
	}
}

.service-page {
	.btn-save {
		background: #7ea68b;
	}
}


.terms-of-sale {
	.text-section {
		margin-bottom: 30px;
	}

	pre {
		width: 100%;
		white-space: pre-wrap;
	}

	p {
		margin-bottom: 0;
	}
}

.contact label {
	margin-bottom: 5px;
}
