/* COLORS */
$colors: (
	dark_green: #3a4f41,
	yellow: #f5cb5c,
	light_green: #e8eddf,
	mint: #cfdbd5,
	orange: #cc6633,
	light: #fff,
	light_grey: #eeeeee,
	grey: #333533,
	footer: #242423,
	font: #000,
	some: #F4F6F5,

);
//	orange: #b35b2f,
//	footer_orange: #f78a53,
@function color($color-name) {
	@return map-get($colors, $color-name);
}
