/*@font-face {
	font-family: "Montserrat";
	src: url("../font/google_font/Montserrat-Regular.ttf");
	src: url("../font/google_font/Montserrat-Regular.ttf") format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: url("../font/google_font/Montserrat-Bold.ttf");
	src: url("../font/google_font/Montserrat-Bold.ttf") format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: url("../font/google_font/Montserrat-Medium.ttf");
	src: url("../font/google_font/Montserrat-Medium.ttf") format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
} */
/*
@font-face {
	font-family: 'montserratregular';
	src: url("../font/webfontkit/montserrat-regular-webfont.woff2") format('woff2'), url("../font/webfonts/montserrat-regular-webfont.woff") format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'montserratbold';
	src: url("../font/webfontkit/montserrat-bold-webfont.woff2") format('woff2'), url("../font/webfontkit/montserrat-bold-webfont.woff") format('woff');
	font-weight: 400;
	font-style: normal;
}
*/

h1 {
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	font-display: swap;
	@include xs {
		font-size: 2.1em!important;
	}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h2 {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-weight: bold;
	font-size: 36px;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include medium {
		font-size: 30px;
	}
}

h3 {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-weight: bold;
	margin: 0;
	padding: 0;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: -0.02em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h4 {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	line-height: 1.6;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	text-decoration: none;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:hover {
		text-decoration: underline;
	}
}

b {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

i {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

small {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

li {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

label {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

textarea {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

select {
	font-family: 'Montserrat', sans-serif;
	font-display: swap;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
