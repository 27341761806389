/* other */

.low-opacity {
	opacity: 0.5;
}

.no-opacity {
	opacity: 0;
}

.service-page {
	background: color(dark_green);
	padding: 117px 0 60px;
	@include medium {
		padding-bottom: 30px;
	}

	h1 {
		color: color(light);
	}

	p {
		color: color(light);
		padding-bottom: 0;
		margin-bottom: 0;
		font-size: 18px;
		font-weight: normal;
	}
}
/* Website */
.website-how-we-do {
	padding: 60px 0;
	@include medium {
		padding: 30px 0;
	}

	h2 {
		padding-left: 0;
	}

	.container {
		padding: 0 30px;
	}
	background: color(mint);

	.ref-block {
		padding: 30px;
		background: color(light);
		@include medium {
			padding: 15px;
		}

		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}

		img {
			height: 60px;
			width: auto;
			padding-bottom: 0;
			margin-bottom: 30px;
			@include medium {
				margin-bottom: 20px;
			}
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
		margin-bottom: 0;
		@include medium {
			padding-top: 5px;
		}
	}

	.btn {
		max-width: 350px;
		margin: 60px auto 0;
	}
}

.service-page-content {
	padding: 60px 0;
	@include medium {
		padding: 30px 0;
	}

	h2 {
		padding-left: 0;
		@include medium_large {
	padding-left: 15px;
}
	}

	.container {
		padding: 0 30px;
		@include medium_large {
			padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
}
	}
	background: color(mint);
}

.drift-page {
	padding: 60px 0;
	@include medium {
		padding: 30px 0;
	}

	h2 {
		padding-left: 0;
	}

	.container {
		padding: 0 30px;
	}
	background: color(mint);

	.drift-block {
		padding: 30px;
		background: color(light);
		@include medium {
			padding: 15px;
		}

		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}

		img {
			height: 60px;
			width: auto;
			padding-bottom: 0;
			margin-bottom: 30px;
			@include medium {
				margin-bottom: 20px;
			}
		}

		p {
			min-height: 230px;
			@include medium {}
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
		margin-bottom: 0;
		@include medium {
			padding-top: 5px;
		}
	}

	.btn {
		max-width: 350px;
		margin: 0 auto 15px;
		float: right;
	}
}
/* Our packages */

.website-packages {
	padding: 60px 0;

	.title {
		margin: 0;
	}

	p {
		min-height: 252px;
		@media screen and (max-width: 1399px) {
			min-height: 273px;
		}
		@include medium_large {
			min-height: 152px;
		}
	}

	h2 {
		padding-left: 0;
		@include medium_large {
			padding-bottom: 5px;
		}
	}

	.container-price-wrapper {
		flex: 1 1 auto;

		img {
			height: 60px;
			width: auto;
			padding-bottom: 0;
			margin-bottom: 30px;
		}
	}

	.container {
		padding: 0 30px;
	}
	background: color(light);

	.ref-wrapper {
		text-align: right;

		a {
			color: color(orange);
			font-weight: bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.ref-block {
		h3 {
			font-size: 24px;
		}
		padding: 30px;
		@include xs {
			padding: 15px;
		}
		//	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.16);
		border: 1px solid color(light_green);
		display: flex;
		flex-direction: column;
		@include medium_large {
			max-width: 48.5%;
		}
		@include medium {
			max-width: 100%;
			flex: 0 0 100%;
		}

		&:hover {
			box-shadow: none;
			transition: 0.3s ease-in-out;
		}
	}

	.full-w-block {
		@include medium_large {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.middle-block {
		@include medium_large {
			margin-left: 0;
		}
	}

	.first-block {
		@include medium_large {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.hosting-description {
		padding: 0 !important;
		margin: 0 !important;
		display: inline-block;
		max-width: 87%;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;

		li {
			font-size: 16px;
			padding-bottom: 10px;

			.fa-check-circle {
				color: color(dark_green);
				margin-right: 5px;
			}

			.fad {
				vertical-align: top;
				display: inline-block;
				margin-top: 1px;
			}

			.fa-times-circle {
				color: #c60f0f;
				margin-right: 5px;
			}
		}
	}

	.btn {
		width: 100%;
		position: relative;
		margin-top: 30px;
	}

	.price-wrapper {
		width: 100%;
		position: relative; //bottom: 60px;
		text-align: right;
		margin-top: 30px;

		small {
			display: inline-block;
			padding-right: 5px;
			font-weight: bold;
			color: color(orange);
		}

		p {
			display: block;
			font-size: 12px;
			min-height: auto;
			padding: 0;
			margin: 0;
			color: #666;
		}

		h2 {
			display: inline-block;
			color: color(orange);
			margin-bottom: 0;
			@include small {
				//padding-bottom: 30px;
			}
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
		@include medium {
			padding-top: 5px;
		}
	}
}

.package-presentation {
	background: color(light);
	padding: 60px 0 30px;
	@include medium {
		padding: 30px 0 15px;
	}

	.title {
		margin-bottom: 30px;
		@include medium {
			margin-bottom: 20px;
		}
	}
}

.included-in-our-packages {
	background: color(light);
	padding: 0 0 60px;
	margin-left: -5px;
	@include medium {
		padding: 0 0 15px;
	}

	h3 {
		margin-bottom: 15px;
		padding-left: 0;
		@include medium {
			margin-bottom: 15px;
		}
	}

	h4 {
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.02em;
		margin-bottom: 15px;
		@include medium {
			margin-bottom: 10px;
		}

		img {
			height: 20px!important;
			width: auto!important;
			vertical-align: top;
			margin-top: 5px;
			margin-right: 5px;
			margin-bottom: 0!important;
		}
	}

	.ref-block {
		p {
			padding-top: 0;
		}
		@include medium_large {
			max-width: 47%;
		}
		@include medium {
			max-width: 100%;
		}
		border: 1px solid #e8eddf;
	}

	.middle-block {
		@include medium_large {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.what-we-can-help-with {
	background: color(light_green);
	padding: 60px 0;
}
/* Webshop packages */

.webshop-packages {
	padding: 60px 0;

	.title {
		margin: 0;
	}

	.container-price-wrapper {
		flex: 1 1 auto;
	}

	.container {
		padding: 0 30px;
	}
	background: color(light);

	ul {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;

		li {
			font-size: 16px;
			padding-bottom: 10px;

			.fa-check-circle {
				color: color(dark_green);
				margin-right: 5px;
			}

			.fad {
				vertical-align: top;
				display: inline-block;
				margin-top: 1px;
			}

			.fa-times-circle {
				color: #c60f0f;
				margin-right: 5px;
			}
		}
	}

	.btn {
		width: 100%;
		position: relative;
		margin-top: 30px;
	}

	.price-wrapper {
		width: 100%;
		position: relative; //bottom: 60px;
		text-align: right;
		margin-top: 30px;

		small {
			display: inline-block;
			padding-right: 5px;
			font-weight: bold;
			color: color(orange);
		}

		p {
			display: block;
			font-size: 12px;
			min-height: auto;
			padding: 0;
			margin: 0;
			color: #666;
		}

		h2 {
			display: inline-block;
			color: color(orange);
			margin-bottom: 0;
			@include small {
				//	padding-bottom: 30px;
			}
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
	}
}

.webshop-border {
	border: 1px solid #e8eddf;
	padding: 30px;
	@include small {
		padding: 15px 15px 0;
	}
}
/* Our SEO packages */
.seo-presentation {
	padding: 60px 0;

	h2 {
		margin-bottom: 15px;
		@include medium {
			margin-bottom: 0;
		}
	}

	p {
		max-width: 700px;
		padding-bottom: 60px;
	}
}
// website packages

.webshop-package {
	.full-w-block {
		width: 50%;
		max-width: 100%;
	}
}

.seo-packages {
	padding-top: 0;

	.seo-block {
		width: 100%;
		padding: 30px;
		border: 1px solid #e8eddf;
		margin-bottom: 30px;
		@include small {
			padding: 15px 15px 0;
		}

		.price-wrapper {
			h2 {
				@include small {
					padding-bottom: 0;
					font-size: 28px;
				}
			}

			.col-md-8 {
				@include small {
					padding-top: 30px;
				}
			}
		}
	}

	.together-block {
		width: 50%;
		display: inline-block;
		@media screen and (max-width: 1199px) {
			width: 100%;
		}
	}

	.alone-block {
		width: 50%;
		display: inline-block;
		@media screen and (max-width: 1199px) {
			width: 100%;
		}
	}

	.container-price-wrapper {
		min-height: 248px;
		@media screen and (max-width: 1399px) {
			min-height: 243px;
		}
		@media screen and (max-width: 1199px) {
			min-height: auto;
			margin-bottom: 60px;
		}
	}

	.container-high-price-wrapper {
		min-height: 814px;

		p {
			min-height: auto;
		}

		h4 {
			font-size: 20px;
			font-weight: normal;
			padding-bottom: 15px;
		}
		@media screen and (max-width: 1399px) {
			min-height: 856px;
		}
		@media screen and (max-width: 1199px) {
			min-height: auto;
			margin-bottom: 60px;
		}
	}

	p {
		max-width: 700px;
		@media screen and (max-width: 1199px) {
			min-height: auto;
		}
	}

	.title {
		margin: 0;
	}

	h3 a {
		font-size: 24px;
		color: color(orange);
		line-height: 30px;
	}

	a {
		color: color(orange);
	}

	.btn {
		color: color(light);
	}
}
/* Hosting */
.hosting-packages {
	padding: 0 15px;

	p {
		max-width: 720px;
		margin-bottom: 60px;
		min-height: auto;
	}

	.title {
		@include small {
			font-size: 30px;
		}
	}
}

.hosting-packages-prices {
	padding: 0 0 60px;
	@include small {
		padding: 15px;
	}

	.title {
		margin: 0;
	}

	p {
		min-height: 130px;
	}

	.container-price-wrapper {
		flex: 1 1 auto;
	}

	.container {
		padding: 0 30px;
	}
	background: color(light);

	.ref-wrapper {
		text-align: right;

		a {
			color: color(orange);
			font-weight: bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.ref-block {
		display: flex;
		flex-direction: column;

		h3 {
			font-size: 24px;
		}
		padding: 30px;
		@include xs {
			padding: 15px;
		}
		border: 1px solid color(light_green);
		min-height: 550px;
		@include medium_large {
			max-width: 48.5%;
		}
		@include medium {
			max-width: 100%;
			flex: 0 0 100%;
		}

		&:hover {
			box-shadow: none;
			transition: 0.3s ease-in-out;
		}
	}

	.full-w-block {
		@include medium_large {
			max-width: 100%;
			flex: 0 0 100%;
		}
		@include small {
			padding: 15px;
		}
	}

	.middle-block {
		@include medium_large {
			margin-left: 0;
		}
		@include small {
			padding: 15px 15px 0;
		}
	}

	.first-block {
		@include medium_large {
			margin-left: 0;
			margin-right: 0;
			@include small {
				padding: 15px;
			}
		}
	}

	.hosting-description {
		padding: 0 !important;
		margin: 0 !important;
		display: inline-block;
		max-width: 87%;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;

		li {
			font-size: 16px;
			padding-bottom: 10px;

			.fa-check-circle {
				color: color(dark_green);
				margin-right: 5px;
			}

			.fad {
				vertical-align: top;
				display: inline-block;
				margin-top: 1px;
			}

			.fa-times-circle {
				color: #c60f0f;
				margin-right: 5px;
			}
		}
	}

	.btn {
		width: 100%;
		position: relative;
		margin-top: 30px;
	}

	.price-wrapper {
		width: 100%;
		position: relative; //bottom: 60px;
		text-align: right;
		margin-top: 30px;

		small {
			display: inline-block;
			padding-right: 5px;
			font-weight: bold;
			color: color(orange);
		}

		p {
			display: block;
			font-size: 12px;
			min-height: auto;
			padding: 0;
			margin: 0;
			color: #666;
		}

		h2 {
			display: inline-block;
			color: color(orange);
			margin-bottom: 0;
			@include small {
				//	padding-bottom: 30px;
			}
		}
	}

	h3 {
		font-weight: bold;
	}

	p {
		padding-top: 15px;
		@include medium_large {
			min-height: 130px;
		}
		@include medium {
			min-height: auto;
			padding-top: 5px;
		}
	}
}
/* Why trinxy */
.why-trinxy-page {
	background: #f5cb5c;
	padding: 117px 0 60px;

	h1 {
		color: color(footer);
	}

	p {
		color: color(footer);
		font-size: 18px;
		font-weight: normal;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.why-trinxy {
	background: color(light);
	padding: 60px 0;

	.container {
		padding: 0 15px;
	}

	.why-trinxy-block {
		background: color(light);
		//	padding: 53px 0 60px;
	@include small {
margin-bottom: 15px;
}
		h2 {
			margin-bottom: 15px;
			@include medium {
				margin-bottom: 20px;
			}
		}

		h3 {
			margin-bottom: 15px;
			margin-top: 30px;
			@include medium {
				margin-bottom: 15px;
			}
		}

		p {
			padding-top: 0;
		}
	}

	.header-text-row {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.inner-section-wrapper {
		margin-bottom: 30px;
		padding: 30px;
		width: 100%;
		margin-left: 16%;
		@media screen and (max-width: 1299px) {
			margin-left: 0;
		}
		@include small {
			width: auto;
			margin-left: 0;
	margin-bottom: 0px;
text-align: center;
		padding: 15px;
		}

		h3 {
			margin-bottom: 15px;
			@include medium {
				margin-bottom: 15px;
			}
		}

		p {
			padding-top: 0;
			min-height: 1px;
		}
	}

	.icon-wrapper {
		width: auto;
		display: inline-block;
		margin-right: 30px;

		img {
			width: auto;
			height: 167px;
			border-radius: 50%;
			@media screen and (max-width: 1299px) {
				height: 130px;
			}
			@media screen and (max-width: 991px) {
				margin-bottom: 15px;
			}
			@include small {
				height: 100px;
				height: 167px;
			}
		}
	}

	.info-wrapper {
		display: inline-block;
		width: auto;
		vertical-align: middle;

		a {
			display: block;
			margin-bottom: 5px;
		}
	}
}
/* Reference page */

.references-page {
	background: color(footer);
	padding: 117px 0 60px;

	a {
		margin-top: 30px;
	}

	h1 {
		color: color(light);
	}

	p {
		color: color(light);
		font-size: 18px;
		font-weight: normal;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.references-page-reference {
	background: color(light_green);

	.container {
		padding: 0 30px;
	}

	.ref-block {
		//		background-color: color(mint); #f3f3f3
		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}
	}

	p {
		padding-top: 15px;
	}

	a {
		color: color(font);
	}

	.ref-wrapper {
		width: 100%;
		text-align: right;
		font-weight: bold;

		a {
			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
			}
		}
	}
}
/* Contact page */

.contact-form-outer-wrapper {
margin: auto;
}

.contact-page {
	background: color(grey);
	padding: 117px 0 60px;

	h1 {
		color: color(light);
	}

	p {
		color: color(light);
		font-size: 18px;
		font-weight: normal;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	a {
		color: color(orange);
		font-size: 18px;
		font-weight: bold;
	}
}

.contact-page-contact {
	background: color(light_green);
min-height: 60vh;
	b {
		font-size: 18px;
	}

	.contact-form-image {
		img {
			max-height: 70px;
			width: auto;
			margin-bottom: 15px;
		}
	}

	.form-description {
		margin-bottom: 30px;
	}

	.contact-card {
		background: #fff;
		padding: 30px;
		margin-bottom: 30px;

		img {
			max-height: 70px;
			width: auto;
			margin-bottom: 15px;
		}

		.button-wrapper {
			margin-top: 30px;
			text-align: right;
		}

		h3 {
			margin-bottom: 10px;
		}
	}

	form {
		width: 100%;
		background: #fff;
		margin-left: 40px;
		@include medium_large {
			margin-left: 0;
			margin-top: 15px;
		}
	}

	.icon-wrapper {
		width: 69px;
		display: inline-block;
		margin-right: 10px;
		vertical-align: top;
		@include medium {
			/*		width: 100%;
			margin-right: 0; */
			margin-bottom: 30px;
		}
	}

	.info-wrapper {
		display: inline-block;
		width: auto;
		padding-top: 5px;
	}

	h2 {
		color: color(font);
		margin-bottom: 15px;
	}

	p {
		color: color(font);
	}

	a {
		color: color(orange);
		font-weight: bold;

		&:hover {
			text-decoration: underline;
			transition: 0.2s ease-in-out;
		}
	}

	.error {
		margin-top: 5px;
		color: color(orange);
		opacity: 0;
		margin-bottom: 0;
		padding-bottom: 15px;
		font-size: 0;
	}

	.contact-page-form {
		@include small {
			padding-bottom: 15px;
		}

		h3 {
			margin-bottom: 10px;
		}

		select {
			background: transparent!important;
		}
	}

	#agree-to {
		margin-top: 15px;
		text-align: left;
		width: 100%;

		#checkbox {
			width: 15px;
			display: inline-block;
			margin-left: 0;
			padding-left: 0;
			float: left;
			margin-top: 6px;
			margin-right: 15px;
		}
	}

	input {
		display: block;
		width: 100%;
		padding: 10px;
		outline: none;
		border: 1px solid color(light_green);
	}

	select {
		display: block;
		width: 100%;
		outline: none;
		padding: 10px;
		border: 1px solid color(light_green);
	}

	.required {
		font-weight: bold;
	}

	label {
		color: color(font);
		margin-bottom: 5px;
	}

	textarea {
		width: 100%;
		display: block;
		outline: none;
		border: 1px solid color(light_green);
		min-height: 200px;
		padding: 10px;
	}

	.submit-wrapper {
		width: 100%;
		margin-top: 15px;
		text-align: right;
	}

	.right-section-contact {
		@include medium_large {
			margin-top: 60px;
		}
	}

	.inner-section-wrapper {
		//	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.16);
		//background: color(mint);
		//	border: 1px solid color(light_green);
		max-width: 350px;
		margin-bottom: 30px;
		padding: 30px;
		@include medium {
			max-width: 96%;
		}
		@include small {
			max-width: 100%;
		}

		p {
			padding: 0;
			margin: 0;
		}

		i {
			background: color(orange);
			padding: 15px;
			border-radius: 100%;
			color: color(light);
			font-size: 26px;
		}
	}
}
/* portfolio page */
/* Reference page */

.portfolio-page {
	background: color(light);

	h1 {
		color: color(orange);
	}

	p {
		color: color(font);
		font-size: 18px;
		font-weight: normal;
	}
}

.references-page-reference {
	background: color(light_green);

	.container {
		padding: 0 30px;
	}

	.ref-block {
		//		background-color: color(mint); #f3f3f3
		&:hover {
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s ease-in-out;
		}
	}

	p {
		padding-top: 15px;
	}

	a {
		color: color(font);

		&:hover {
			text-decoration: none;
		}
	}

	.ref-wrapper {
		width: 100%;
		text-align: right;
		font-weight: bold;

		a {
			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
			}
		}
	}
}
/* Article pages */

.article-page {
	background: color(light);

	img {
		width: 100%;
		height: auto;
		margin-bottom: 30px;
	}

	h2 {
		margin-bottom: 15px;
		margin-top: 30px;
		max-width: 700px;
	}

	p {
		max-width: 700px;
	}
}
/* Portfolio items */

.details-block {
	background: color(light);

	h2:not(:first-child) {
		margin-bottom: 0;
		margin-top: 30px;
	}

	h2 {
		margin-bottom: 0;
	}
}
/* Consolutation */

.consolutation-wrapper {
	h3:not(:first-child) {
		margin-top: 30px;
	}

	h3 {
		margin-bottom: 15px;
		@include medium {
			margin-bottom: 15px;
		}
	}

	.btn {
		margin-top: 30px;
	}
}
/* Terms of sale */
.terms-of-sale {
	padding: 60px 0;
	background: color(light);

	h2:not(:first-child) {
		padding-top: 15px;
	}

	h2 {
		margin-bottom: 15px;
	}

	p {
		padding-top: 0;
	}

	ol {
		margin-bottom: 0;

		li {
			padding-bottom: 15px;
			font-size: 16px;
			line-height: 1.6;
		}
	}
}
/* News */
.news-header {
	background: color(mint);
	padding: 117px 0 0;

	p {
		font-size: 18px;
		padding: 0;
	}

	h1 {
		color: color(font);
	}

	h2 {
		padding-left: 0;
	}
}
/* references */

.referenser-header {
	background: color(light_green);
	padding: 117px 0 0;

	p {
		font-size: 18px;
		padding: 0;
	}

	h1 {
		color: color(font);
	}
}
/* Single references */
.single-referense {
	background: color(mint);
	padding: 117px 0 60px;

	.header-image img {
		width: 100%;
	}

	p {
		font-size: 18px;
		padding: 0;
	}

	a {
		color: color(orange);
	}

	h1 {
		color: color(font);
	}

	.header-image {
		img {
			border-radius: 15px;
			box-shadow: 0 6px 5px 0 rgba(0,0,0,0.35);
		}
	}
}

.about-the-mission {
	background: color(light_grey);

	h2 {
		margin-bottom: 20px;
	}
}

.description-block {
	background: color(light);
	padding: 30px 30px 15px 30px;
	box-shadow: 4px 5px 5px 0 rgba(0,0,0,0.35);
	max-width: 450px;
	@include medium {
		max-width: 100%;
		margin-bottom: 30px;
		padding: 15px 15px 1px 15px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		/*	background: color(light);
		padding: 30px 30px 15px;
		list-style: none;
		box-shadow: 4px 5px 5px 0 rgba(0,0,0,0.35);
*/
		li {
		//	line-height: 1.6;
		}
.desc-text {
margin-top: 5px;
margin-bottom: 15px;
}
	}

	table {
		width: 100%;

		td {
			padding-bottom: 15px;
		}

		tr td:first-child {
			font-weight: bold;
			width: 40%;
		}
	}
}

.portfolio-video-section {
	background: color(mint);
	z-index: 1;
	position: relative;
}

.portfolio-video-section video {
	max-width: 80%;
	width: 100%;
	height: auto;
	margin-left: 10%;
	position: relative;
	@include medium {
		max-width: 100%;
		margin-left: 0;
	}
}

.the-solution {
	background: color(light);
	z-index: 2;
	position: relative;

	h2 {
		margin-bottom: 20px;
	}

	.visit-project-wrapper {
		text-align: center;
		@include medium {
			margin-top: 60px;
		}

		img {
			max-width: 200px;
			display: block;
			margin: 0 auto 60px;
		}

		.btn {
			max-width: 200px;
		}
	}
}

.additinal-images {
	//	overflow-x: hidden;
	padding-top: 0;

	img {
		width: 100%;
		height: auto;
		cursor: pointer;
		border-radius: 15px;

		&:hover {
			transition: 0.2s ease-in-out;
			/*	transform: scale(1.1); */
			opacity: 0.7;
		}
	}

	.gallery__item {
		margin-bottom: 30px;
	}
}

.owl-slider {
	margin-left: -25%;
	margin-right: -25%;

	.owl-dot {
		display: none;
	}

	.owl-nav {
		z-index: 10;
		position: absolute;
		width: 64%;
		margin: auto 18%;
		top: 50%;
		transform: translate(0%,-50%);
	}

	.owl-prev {
		float: left;
		color: white!important;
		font-size: 80px!important;

		&:focus-visible {
			outline: none!important;
		}

		&:focus {
			outline: none!important;
		}

		span {
			text-shadow: 1px 1px 2px black;

			&:focus-visible {
				outline: none!important;
			}
		}
	}

	.owl-next {
		float: right;
		color: white!important;
		font-size: 80px!important;

		&:focus-visible {
			outline: none!important;
		}

		&:focus {
			outline: none!important;
		}

		span {
			text-shadow: 1px 1px 2px black;

			&:focus-visible {
				outline: none!important;
			}
		}
	}
}

.our-clients {
	img {
		max-width: 50%;
		margin-bottom: 30px;
		@include medium {
			max-width: 90%;
		}
	}
}
/* Service packages */
.desktop-list {
	@include medium_large {
		display: none;
	}
}

.package-price-wrapper-1 {
	background: color(yellow);
}

.package-price-wrapper-2 {
	background: color(orange);
}

.package-price-wrapper-3 {
	background: color(dark_green);

	p {
		color: #fff;
	}
}

.package-price-wrapper-4 {
	background: color(footer);

	p {
		color: #fff;
	}
}

.service-packages-row {
	h3 {
		margin-bottom: 15px;
	}

	.col-lg-4 {
		background: #fff;
		padding: 0;
	}

	.col-5 {
		background: #fff;
		padding: 0;
	}

	.options-price-outer-wrapper {
		background: color(mint);
	}
}

.options-price-outer-wrapper {
	.col-3 {
		padding: 0 0 0 10px;
	}

	.col-7 {
		padding: 0 0 0 10px;
	}

	.package-individual-wrapper {
		background: #fff;
	}
}

.package-price-weapper {
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;
	text-align: center;
	max-width: 80%;
	bottom: 0;
	padding: 10px;
	position: relative;
	margin: auto;

	p {
		margin: 0;
		padding: 0;
		font-weight: bold;
		color: color(orange);
	}
}

.package-head {
	height: 170px;
	text-align: center;
	padding-top: 30px;
	margin-bottom: 30px;

	img {
		display: block;
		margin: auto;
		max-height: 60px;
		width: auto;
		height: 100%;
		margin-bottom: 15px;
	}
}

.package-head-left {
	height: 170px;
	margin-bottom: 30px;
	padding: 15px;
}

.option-wrapper {
	//	height: 120px;
	padding: 20px;
	@include medium_large {
		height: auto;
	}

	b {
		margin-bottom: 10px;
		display: block;
	}
}

.package-options {
	.inner-options-wrapper {
		text-align: center;
		height: 120px;
		position: relative;

		i {
			font-size: 26px;
			//	margin-top: 45px;
			position: absolute;
			top: 40%;
			left: 0;
			width: 100%;
		}

		p {
			font-weight: bold;
			display: block;
			//	padding-top: 45px;
			position: absolute;
			top: 40%;
			left: 0;
			width: 100%;
		}

		&:nth-of-type(even) {
			background: #f3f3f3;
		}
	}
}

.option-wrapper {
	&:nth-of-type(odd) {
		background: #f3f3f3;
	}
}
/* pricelist */

.pricelist-table {
	.title {
		margin-bottom: 15px;
	}

	table {
		width: 100%;
		margin-top: 30px;

		.table-price {
			color: color(orange);
			font-weight: bold;
			font-size: 18px;
		}

		.text-right {
			text-align: right;
		}

		tr {
			border-bottom: 1px solid #ccc;
		}

		th {
			padding: 20px 0;
		}

		td {
			padding: 20px 0;
		}
	}
}

.after-text {
	text-align: right;
	margin-top: 15px;

	p {
		font-size: 14px;
	}
}

.faq-container {
	padding: 90px 0;
	background: color(light_green);
}

.phone-list {
	display: none;
	@include medium_large {
		display: flex;
		margin-bottom: 30px;
	}

	.package-individual-wrapper {
		background: #fff;
		margin-bottom: 30px;
	}

	.inner-options-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		height: auto;
		text-align: left;
		padding: 15px;

		.inner-text {
			text-align: left;
		}

		b {
			margin-bottom: 5px;
			display: block;
		}

		p {
			font-weight: 400;
			display: block;
			position: relative;
			top: 0;
			left: 0;
			width: auto;
			margin: 0;
			padding: 0;
		}
	}
}
