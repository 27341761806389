.pll-parent-menu-item {
	a {
		color: #000!important;
		font-size: 0!important;

		&:after {
			content: "\f1ab";
			font-family: FontAwesome;
			color: #000!important;
			font-size: 20px;
			font-weight: 900;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		}

		img {
			display: none;
		}
	}

	ul {
		li {
			a {
				font-size: 18px!important;
				&:after {
					display: none;
				}

				img {
					display: block;
				}
			}
		}
	}
}

.dark-mode {
	.stick {
		background-color: #000;
	}

	.menu {
		//	background: color(light);
		li {
			color: color(font);
			font-family: 'Montserrat', sans-serif;

			a {
				color: color(font);
				font-family: 'Montserrat', sans-serif;
				font-weight: bold;

				&:hover {
					color: color(orange);
					transition: 0.2s ease-in-out;
				}
			}
		}
	}

	.dropdown-item {
		color: color(font);

		a {
			color: color(font)!important;

			&:hover {
				color: color(orange);
				background: transparent;
			}
		}

		&:hover {
			color: color(orange);
			background: transparent;
		}
	}
	.pll-parent-menu-item {
		a {
			color: #000!important;
			font-size: 0;

			&:after {
				content: "\f1ab";
				font-family: FontAwesome;
				color: #000!important;
				font-size: 20px;
				font-weight: 900;
		    font-style: normal;
		    font-variant: normal;
		    text-rendering: auto;
		    -webkit-font-smoothing: antialiased;
			}

			img {
				display: none;
			}
		}

		ul {
			li {
				a {
					font-size: 18px!important;
					&:after {
						display: none;
					}

					img {
						display: block;
					}
				}
			}
		}
	}
}

.light-mode {
	.stick {
		background-color: color(light);
	}

	.menu {
		//	background: color(light);
		li {
			color: color(light);

			a {
				color: color(light);

				&:hover {
					color: color(orange);
					transition: 0.2s ease-in-out;
				}
			}
		}
	}

	.dropdown-item {
		color: color(font)!important;

		a {
			color: color(font)!important;

			&:hover {
				color: color(orange)!important;
				background: transparent;
			}
		}

		&:hover {
			color: color(orange);
			background: transparent;
		}
	}

	.pll-parent-menu-item {
		a {
			color: #fff!important;
			font-size: 0;

			&:after {
				content: "\f1ab";
				font-family: FontAwesome;
				color: #fff!important;
				font-size: 20px;
				font-weight: 900;
font-style: normal;
font-variant: normal;
text-rendering: auto;
-webkit-font-smoothing: antialiased;
			}

			img {
				display: none;
			}
		}

		ul {
			li {
				a {
color:#000!important;
font-size: 18px!important;
					&:after {
						display: none;
					}

					img {
						display: block;
					}
				}
			}
		}
	}


}

nav {
	z-index: 100;
	position: fixed;
	width: 100%;
	height: 80px;
	@include medium {
		height: 60px;
	}

	.menu-wrapper {
		padding-top: 10px;
	}

	.current-menu-parent {
		color: color(orange)!important;

		a {
			color: color(orange)!important;
		}

		.dropdown-item {
			color: color(font)!important;

			a {
				color: color(font)!important;

				&:hover {
					color: color(orange)!important;
					background: transparent;
				}
			}

			&:hover {
				color: color(orange)!important;
				background: transparent;
			}
		}
	}

	.current-menu-item {
		color: color(orange)!important;

		a {
			color: color(orange)!important;
		}
	}

	.logo {
		img {
			height: 36px;
			width: auto;
			margin-top: 11px;
			z-index: 101;
			position: relative;
			@include medium {
				margin-top: 5px;
				height: 30px;
			}
		}
	}

	.menu {
		float: right;
		right: 0;
		margin-top: 20px;
		cursor: pointer;
		@include medium {
			display: none;
			height: 100vh;
			position: absolute;
			padding-top: 30px;
			left: 0;
			z-index: 100;
			top: 33px;
			padding-bottom: 80px;
			overflow-x: scroll;
			background: color(light);
		}

		li {
			display: inline-block;
			margin-left: 30px;
			font-weight: bold;
			font-size: 18px;
			cursor: pointer;
			@include medium {
				display: block;
				margin-left: 0;
				font-size: 24px;
				margin-bottom: 15px;
			}

			a {
				color: color(font);
				font-weight: bold;
				font-size: 18px;
				@include medium {
					font-size: 20px;
				}
			}
		}
	}

	.dropdown-items {
		display: none;

		li {
			a {
				color: color(font)!important;

				&:hover {
					color: color(orange);
					transition: 0.2s ease-in-out;
				}
			}
		}
	}
}

header {
	background: color(light_green);
	padding: 177px 0 120px;
	@include medium {
		padding: 117px 0 60px;
	}

	.header-text-row {
		position: relative;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	h1 {
		color: color(orange);
		font-size: 48px;
		line-height: 59px;
		letter-spacing: -0.02em;
		@include medium {
			line-height: 48px;
			margin-bottom: 20px;
		}
	}

	p {
		font-weight: normal;
		margin: 30px 0 60px;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: -0.02em;
		@include medium {
			margin: 0 0 30px;
		}
	}

	.header-image {
		@include medium {
			display: none;
		}
	}

	img {
		width: 110%;
		margin-left: 60px;
		max-width: 110%;
		height: auto;
		text-align: right;
		@include xl {
			margin-left: 120px;
		}
		@include large {
			margin-left: 0;
		}
		@include medium {
			width: 100%;
			max-width: 100%;
		}
	}
}

.dropdown-items {
	position: absolute;
	background: white;
	margin: 0;
	padding: 15px 30px;
	font-weight: normal!important;
	font-size: 18px;
	box-shadow: 0 16px 16px -9px rgba(0,0,0,0.2);
	@include medium {
		position: relative;
		box-shadow: none;
	}

	li {
		display: block!important;
		margin-left: 0px!important;
		width: 100%;
		padding: 5px;
		font-weight: normal!important;

		a {
			display: block;
			font-weight: normal!important;
			margin: 0;
			padding: 0;
		}
	}
}

.active {
	display: block;
}

.btn-green {
	@include small {
		margin-bottom: 30px;
	}
}
/* Hamburger */
.hamburger {
	width: 60px;
	height: 60px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	z-index: 101;
	right: 10px;
	margin: auto;
	cursor: pointer;
	display: none;
	@include medium {
		display: flex;
	}
}

.stick {
	width: 30px;
	height: 4px;
	border-radius: 4px;
	margin-bottom: 7px;
	display: inline-block;
	background: black;
}

.stick:last-child {
	margin-bottom: 0;
}

.stick-1.open {
	animation: stick-1-open 0.6s ease-out forwards;
}

.stick-2.open {
	animation: stick-2-open 0.6s linear forwards;
}

.stick-3.open {
	animation: stick-3-open 0.6s linear forwards;
}
/* Dot */
@keyframes stick-1-open {
	0% {
		width: 40px;
	}

	40% {
		background-color: #cc6633;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		transform: translate(24px, 0px);
	}

	75%,
	80% {
		width: 8px;
		transform: translate(30px, -50px);
		animation-timing-function: cubic-bezier(0,1,1,1);
	}

	100% {
		background-color: #cc6633;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		transform: translate(24px, 26px);
	}
}
@keyframes stick-2-open {
	80% {
		background-color: #000;
		transform: translate(0px, 0px) rotate(0deg);
	}

	100% {
		background-color: #000;
		transform: translate(4px, 0px) rotate(45deg);
	}
}
@keyframes stick-3-open {
	80% {
		background-color: #000;
		transform: translate(0px, 0px) rotate(0deg);
	}

	100% {
		background-color: #000;
		transform: translate(4px, -11px) rotate(-45deg);
	}
}

.stick-1.close {
	width: 8px;
	transform: translate(27px, 26px);
	animation: stick-1-close 0.6s ease-out forwards;
}

.stick-2.close {
	transform: translate(0px, 0px) rotate(40deg);
	animation: stick-2-close 0.6s ease-out forwards;
}

.stick-3.close {
	transform: translate(0px, -23px) rotate(-40deg);
	animation: stick-3-close 0.6s ease-out forwards;
}
@keyframes stick-1-close {
	0%,
	70% {
		width: 0;
	}

	100% {
		width: 30px;
		transform: translate(0, 0);
	}
}
@keyframes stick-2-close {
	0% {
		background-color: #cc6633;
		width: 30px;
	}

	20% {
		background-color: #cc6633;
		width: 4px;
		transform: translate(0, 0px) rotate(40deg);
	}

	40% {
		background-color: #000;
		width: 0;
	}

	65% {
		transform: translate(0, -70px);
		animation-timing-function: cubic-bezier(0,1,1,1);
	}

	80% {
		width: 0;
	}

	100% {
		width: 30px;
		transform: translate(0, 0px);
	}
}
@keyframes stick-3-close {
	0% {
		background-color: #cc6633;
		width: 30px;
	}

	20% {
		background-color: #cc6633;
		width: 4px;
		transform: translate(0, -23px) rotate(-40deg);
	}

	40% {
		background-color: #000;
	}

	65% {
		transform: translate(0, -93px);
		animation-timing-function: cubic-bezier(0,1,1,1);
	}

	90% {
		width: 4px;
	}

	100% {
		width: 30px;
		transform: translate(0, 0px);
	}
}
