@charset "UTF-8"; /*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

*,
*::after,
*::before {
	box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}

* {
	padding: 0;
	margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

.h1,
h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	.h1,
	h1 {
		font-size: 2.5rem;
	}
}

.h2,
h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	.h2,
	h2 {
		font-size: 2rem;
	}
}

.h3,
h3 {
	font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
	.h3,
	h3 {
		font-size: 1.75rem;
	}
}

.h4,
h4 {
	font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
	.h4,
	h4 {
		font-size: 1.5rem;
	}
}

.h5,
h5 {
	font-size: 1.25rem;
}

.h6,
h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol,
ul {
	padding-left: 2rem;
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0;
}

b,
strong {
	font-weight: bolder;
}

.small,
small {
	font-size: 0.875em;
}

a {
	text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
}

button:focus {
	outline: dotted 1px;
	outline: -webkit-focus-ring-color auto 5px;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role=button] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

[type=button],
[type=reset],
[type=submit],
button {
	-webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

textarea {
	resize: vertical;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px;
	}
}
@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1140px;
	}
}
@media (min-width: 1800px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) / 2);
	padding-left: calc(var(--bs-gutter-x) / 2);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0;
}

.row-cols-auto > * {
	flex: 0 0 auto;
	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;
	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;
	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;
	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;
	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.3333333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.6666666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.3333333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.6666666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.3333333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.6666666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.offset-1 {
	margin-left: 8.3333333333%;
}

.offset-2 {
	margin-left: 16.6666666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.3333333333%;
}

.offset-5 {
	margin-left: 41.6666666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.3333333333%;
}

.offset-8 {
	margin-left: 66.6666666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.3333333333%;
}

.offset-11 {
	margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
	.col-sm {
		flex: 1 0 0;
	}

	.row-cols-sm-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-sm-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-sm-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-sm-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-sm-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-sm-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-sm-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}

	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}

	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}

	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}

	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}

	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-sm-0 {
		margin-left: 0;
	}

	.offset-sm-1 {
		margin-left: 8.3333333333%;
	}

	.offset-sm-2 {
		margin-left: 16.6666666667%;
	}

	.offset-sm-3 {
		margin-left: 25%;
	}

	.offset-sm-4 {
		margin-left: 33.3333333333%;
	}

	.offset-sm-5 {
		margin-left: 41.6666666667%;
	}

	.offset-sm-6 {
		margin-left: 50%;
	}

	.offset-sm-7 {
		margin-left: 58.3333333333%;
	}

	.offset-sm-8 {
		margin-left: 66.6666666667%;
	}

	.offset-sm-9 {
		margin-left: 75%;
	}

	.offset-sm-10 {
		margin-left: 83.3333333333%;
	}

	.offset-sm-11 {
		margin-left: 91.6666666667%;
	}
}
@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0;
	}

	.row-cols-md-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-md-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-md-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-md-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-md-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-md-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-md-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-md-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}

	.col-md-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-md-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.col-md-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}

	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-md-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}

	.col-md-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-md-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}

	.col-md-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}

	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-md-0 {
		margin-left: 0;
	}

	.offset-md-1 {
		margin-left: 8.3333333333%;
	}

	.offset-md-2 {
		margin-left: 16.6666666667%;
	}

	.offset-md-3 {
		margin-left: 25%;
	}

	.offset-md-4 {
		margin-left: 33.3333333333%;
	}

	.offset-md-5 {
		margin-left: 41.6666666667%;
	}

	.offset-md-6 {
		margin-left: 50%;
	}

	.offset-md-7 {
		margin-left: 58.3333333333%;
	}

	.offset-md-8 {
		margin-left: 66.6666666667%;
	}

	.offset-md-9 {
		margin-left: 75%;
	}

	.offset-md-10 {
		margin-left: 83.3333333333%;
	}

	.offset-md-11 {
		margin-left: 91.6666666667%;
	}
}
@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0;
	}

	.row-cols-lg-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-lg-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-lg-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-lg-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-lg-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-lg-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-lg-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}

	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}

	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}

	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}

	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}

	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-lg-0 {
		margin-left: 0;
	}

	.offset-lg-1 {
		margin-left: 8.3333333333%;
	}

	.offset-lg-2 {
		margin-left: 16.6666666667%;
	}

	.offset-lg-3 {
		margin-left: 25%;
	}

	.offset-lg-4 {
		margin-left: 33.3333333333%;
	}

	.offset-lg-5 {
		margin-left: 41.6666666667%;
	}

	.offset-lg-6 {
		margin-left: 50%;
	}

	.offset-lg-7 {
		margin-left: 58.3333333333%;
	}

	.offset-lg-8 {
		margin-left: 66.6666666667%;
	}

	.offset-lg-9 {
		margin-left: 75%;
	}

	.offset-lg-10 {
		margin-left: 83.3333333333%;
	}

	.offset-lg-11 {
		margin-left: 91.6666666667%;
	}
}
@media (min-width: 1200px) {
	.col-xl {
		flex: 1 0 0;
	}

	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}

	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}

	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}

	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}

	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}

	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-xl-0 {
		margin-left: 0;
	}

	.offset-xl-1 {
		margin-left: 8.3333333333%;
	}

	.offset-xl-2 {
		margin-left: 16.6666666667%;
	}

	.offset-xl-3 {
		margin-left: 25%;
	}

	.offset-xl-4 {
		margin-left: 33.3333333333%;
	}

	.offset-xl-5 {
		margin-left: 41.6666666667%;
	}

	.offset-xl-6 {
		margin-left: 50%;
	}

	.offset-xl-7 {
		margin-left: 58.3333333333%;
	}

	.offset-xl-8 {
		margin-left: 66.6666666667%;
	}

	.offset-xl-9 {
		margin-left: 75%;
	}

	.offset-xl-10 {
		margin-left: 83.3333333333%;
	}

	.offset-xl-11 {
		margin-left: 91.6666666667%;
	}
}
@media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0;
	}

	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}

	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}

	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}

	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}

	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}

	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-xxl-0 {
		margin-left: 0;
	}

	.offset-xxl-1 {
		margin-left: 8.3333333333%;
	}

	.offset-xxl-2 {
		margin-left: 16.6666666667%;
	}

	.offset-xxl-3 {
		margin-left: 25%;
	}

	.offset-xxl-4 {
		margin-left: 33.3333333333%;
	}

	.offset-xxl-5 {
		margin-left: 41.6666666667%;
	}

	.offset-xxl-6 {
		margin-left: 50%;
	}

	.offset-xxl-7 {
		margin-left: 58.3333333333%;
	}

	.offset-xxl-8 {
		margin-left: 66.6666666667%;
	}

	.offset-xxl-9 {
		margin-left: 75%;
	}

	.offset-xxl-10 {
		margin-left: 83.3333333333%;
	}

	.offset-xxl-11 {
		margin-left: 91.6666666667%;
	}
}

.table {
	--bs-table-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #dee2e6;
}

.table > :not(caption) > * > * {
	padding: 0.5rem;
	background-color: var(--bs-table-bg);
	background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
	border-bottom-width: 1px;
}

.table > tbody {
	vertical-align: inherit;
}

.table > thead {
	vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
	border-bottom-color: currentColor;
}

.caption-top {
	caption-side: top;
}

.table-sm > :not(caption) > * > * {
	padding: 0.25rem;
}

.table-bordered > :not(caption) > * {
	border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
	border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	--bs-table-accent-bg: var(--bs-table-striped-bg);
	color: var(--bs-table-striped-color);
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
	--bs-table-accent-bg: var(--bs-table-hover-bg);
	color: var(--bs-table-hover-color);
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}
@media (min-width: 576px) {
	.order-sm-first {
		order: -1 !important;
	}

	.order-sm-0 {
		order: 0 !important;
	}

	.order-sm-1 {
		order: 1 !important;
	}

	.order-sm-2 {
		order: 2 !important;
	}

	.order-sm-3 {
		order: 3 !important;
	}

	.order-sm-4 {
		order: 4 !important;
	}

	.order-sm-5 {
		order: 5 !important;
	}

	.order-sm-last {
		order: 6 !important;
	}
}
@media (min-width: 768px) {
	.order-md-first {
		order: -1 !important;
	}

	.order-md-0 {
		order: 0 !important;
	}

	.order-md-1 {
		order: 1 !important;
	}

	.order-md-2 {
		order: 2 !important;
	}

	.order-md-3 {
		order: 3 !important;
	}

	.order-md-4 {
		order: 4 !important;
	}

	.order-md-5 {
		order: 5 !important;
	}

	.order-md-last {
		order: 6 !important;
	}
}
@media (min-width: 992px) {
	.order-lg-first {
		order: -1 !important;
	}

	.order-lg-0 {
		order: 0 !important;
	}

	.order-lg-1 {
		order: 1 !important;
	}

	.order-lg-2 {
		order: 2 !important;
	}

	.order-lg-3 {
		order: 3 !important;
	}

	.order-lg-4 {
		order: 4 !important;
	}

	.order-lg-5 {
		order: 5 !important;
	}

	.order-lg-last {
		order: 6 !important;
	}
}
@media (min-width: 1200px) {
	.order-xl-first {
		order: -1 !important;
	}

	.order-xl-0 {
		order: 0 !important;
	}

	.order-xl-1 {
		order: 1 !important;
	}

	.order-xl-2 {
		order: 2 !important;
	}

	.order-xl-3 {
		order: 3 !important;
	}

	.order-xl-4 {
		order: 4 !important;
	}

	.order-xl-5 {
		order: 5 !important;
	}

	.order-xl-last {
		order: 6 !important;
	}
}
@media (min-width: 1400px) {
	.order-xxl-first {
		order: -1 !important;
	}

	.order-xxl-0 {
		order: 0 !important;
	}

	.order-xxl-1 {
		order: 1 !important;
	}

	.order-xxl-2 {
		order: 2 !important;
	}

	.order-xxl-3 {
		order: 3 !important;
	}

	.order-xxl-4 {
		order: 4 !important;
	}

	.order-xxl-5 {
		order: 5 !important;
	}

	.order-xxl-last {
		order: 6 !important;
	}
}
/*# sourceMappingURL=bootstrap.css.map */
