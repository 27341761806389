footer {
	padding: 60px 0 0;
	background: color(footer);
	@include medium {
		padding: 30px 0 0;
	}

	h3 {
		color: color(light);
		margin-bottom: 15px;
		@include medium {
			margin-bottom: 10px;
		}
	}

	.logo {
		img {
			height: 36px;
			width: auto;
		}
	}

	p {
		color: color(light);
		font-size: 16px;
		margin-top: 15px;
		margin-bottom: 30px;
	}

	i {
		color: color(orange);
		font-size: 40px;
		margin-right: 15px;

		&:hover {
			color: color(light);
			transition: 0.2s ease-in-out;
		}
	}

	.contact-info {
		p {
			line-height: 1.6;
			padding: 0;
			margin: 0;
			font-size: 14px;
		}

		a {
			display: block;
			font-weight: bold;
			color: color(orange);
			line-height: 1.6;
			font-size: 16px;
			margin-bottom: 15px;

			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
			}
		}

		.address-outer-wrapper {
			//	padding: 15px 0;
		}

		.address-wrapper {
			padding: 0;
			margin: 0;
			padding-bottom: 15px;
		}
	}

	b {
		margin-top: 30px;
		color: color(light);
		font-size: 14px;
	}

	ul {
		color: color(light);
		font-size: 16px;
		padding-left: 15px;
		margin-left: 0;
		/*@include medium {
			margin-top: 15px;
		}*/

		li {
			font-size: 16px;
			padding-bottom: 5px;

			a {
				font-size: 16px;
				color: color(light);

				&:hover {
					text-decoration: underline;
					transition: 0.2s ease-in-out;
					color: color(orange);
				}
			}

			&:hover {
				text-decoration: underline;
				transition: 0.2s ease-in-out;
				color: color(orange);
			}
		}
	}

	.col-12 {
		@include medium {
			margin-bottom: 60px;
		}
	}

	.copyright {
		text-align: center;
		padding: 60px 0 0;
		margin-bottom: -30px;
		font-size: 14px;
	}
}

.tawk-icon-right img {
	display: none;
}
.tawk-footer {
	display: none!important;
}
