.single-news {
	max-width: 800px;
	width: 100%;
	margin: auto;

h1 {
margin-bottom: 30px;
@include medium {
margin-bottom: 15px;
}
}

	.post-date {
		padding-top: 60px;
	}

	img {
		width: 100%;
		height: auto;
		margin-bottom: 30px;
	}

	h2 {
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 15px;
		padding: 0;
	}

	h3 {
		margin-top: 30px;
		margin-bottom: 15px;
		font-size: 24px;
	}

	.written-by {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 60px;

		.inner-wrapper {
			padding: 15px;
			background: color(some);
			max-width: 48%;
			width: 100%;
            @include small {
			max-width: 100%;
            margin-bottom: 15px;
            }
		}

		.author-info {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			.author-image {
				width: 25%;

				img {
					border-radius: 100%;
					background: #fff;
					width: 100%;
					height: auto;
					margin-bottom: 0;
				}
			}

			.author-name {
				width: 70%;

				p {
					margin-bottom: 0;
					padding: 0;
				}

				b {
					margin-bottom: 0;
					padding: 0;
				}
			}
		}

		.some-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: end;

			.trinxy-share-icons {
				width: 100%;
				text-align: right;

				.individual-share {
					display: inline-block;
					margin-left: 15px;

					i {
						font-size: 32px;
						color: #000;
					}

					.fa-facebook {
						&:hover {
							color: #2c64f6;
							transition: 0.2s ease-in-out;
						}
					}

					.fa-linkedin {
						&:hover {
							color: #2d64bc;
							transition: 0.2s ease-in-out;
						}
					}

					.fa-square-x-twitter {
						&:hover {
							color: #e9eaea;
							transition: 0.2s ease-in-out;
						}
					}
				}
			}
		}
	}
}

.single-news-more-news {
	h2 {}
}
