.products-count-and-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .woocommerce-notices-wrapper {
        width: 100%;
    }
}
.woocommerce-products-header {
h1 {
color: color(font);
}
.term-description {
max-width: 600px;

}
}

.trinxy-product-wrapper {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: start;
        flex-wrap: wrap;

        a {
            text-decoration: none!important;
        }
    }

    ul > li {
        box-sizing: border-box;
        margin: 0 15px 15px 0;
        width: calc(1/4*100% - (1 - 1/4)*15px);
        text-decoration: none!important;
    }

    li:nth-child(4n) {
        margin-right: 0;
    }

    .price {
        width: 100%;
        display: block;
        margin-bottom: 30px;
        margin-top: 10px;
        font-weight: bold;
        color: color(orange)!important;
    }
img {
width: 100%;
}
}

.woocommerce-loop-product__title {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #000!important;
    text-decoration: none!important;
    padding-bottom: 0;
    margin-bottom: 5px!important;
}

.add_to_cart_button {
    color: #fff!important;
    background: color(dark_green);
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
    height: 42px;
}

.single_add_to_cart_button {
    color: #fff!important;
    background: color(dark_green);
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
    margin-top: 15px;
    margin-bottom: 15px;
}
/* single product */
.single-product-section {
    padding-top: 90px;

    .price {
        color: color(orange)!important;
        font-weight: bold!important;
        font-size: 18px;
    }

    .product_meta {
        display: none;
    }

    .product_title {
        font-size: 26px;
    }
}

.product-type-simple {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-type-variable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.woocommerce-product-gallery {
    width: 45%;
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    .woocommerce-product-gallery__trigger {
        position: absolute;
        z-index: 2;
        top: 15px;
        left: 15px;
    }

    .flex-viewport {
        border: 1px solid #f6f6f6;
        margin-bottom: 15px;
    }
}

.entry-summary {
    width: 47%;
    display: inline-block;
}

.woocommerce-tabs {
    width: 100%;
}

.related {
    width: 100%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: start;
        flex-wrap: wrap;

        a {
            text-decoration: none!important;
        }
    }

    ul > li {
        box-sizing: border-box;
        margin: 0 15px 15px 0;
        width: calc(1/4*100% - (1 - 1/4)*15px);
        text-decoration: none!important;
    }

    li:nth-child(4n) {
        margin-right: 0;
    }

    .price {
        width: 100%;
        display: block;
        margin-bottom: 30px;
        color: color(orange)!important;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .woocommerce-LoopProduct-link {
        h2 {
            color: #000;
            font-size: 18px;
        }
    }
}

.quantity {
    .input-text {
        max-width: 40px;
    }
}

.flex-control-thumbs {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
        max-width: 20%;
        width: 100%;
        display: inline-block;
        margin-right: 10px;

        img {
            width: 100%;
            height: auto;
            border: 1px solid #f6f6f6;
        }
    }
}

.woocommerce-tabs {
    background: color(light_green);
    margin-top: 60px;

    ul {
        list-style: none;
    }

    .wc-tabs {
        margin: 0;
        padding: 0;
        width: 100%;
        background: #fff;
        background-color: #fff;

        li {
            display: inline-block;
            padding: 5px 15px;
            background: color(mint);
            background-color: color(mint);

            a {
                color: #000;
            }
        }
    }

    .active {
        background: color(light_green)!important;
        background-color: color(light_green)!important;
    }

    .woocommerce-Tabs-panel {
        padding: 30px;

        h2 {
            font-size: 20px!important;
            margin-bottom: 0px!important;
            padding-bottom: 15px!important;
        }

        h3 {
            font-size: 20px!important;
            margin-bottom: 0px!important;
            padding-bottom: 15px!important;
        }
    }
}

.woocommerce-product-details__short-description {
    h2 {
        font-size: 20px!important;
        margin-bottom: 0px!important;
        padding-bottom: 15px!important;
    }

    h3 {
        font-size: 18px!important;
        margin-bottom: 0px!important;
        padding-bottom: 15px!important;
    }
}

.comment-form-comment {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.form-submit {
    #submit {
        color: #fff!important;
        background: color(dark_green);
        padding: 10px 15px;
        font-size: 14px;
        border-radius: 15px;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.5px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

#reply-title {
    margin-bottom: 15px;
    display: block;
    font-weight: bold;
}

#comment {
    padding: 5px;
}

.commentlist {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
        background: #fff;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;

        img {
            display: none;
        }
    }
}
/* Star rating */

.comment-form-rating p.stars [class^="star-"],
.comment-form-rating p.stars [class*=" star-"],
.star-rating {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 1em;
}

.star-rating {
    overflow: hidden;
    position: relative;
    height: 1em;
    width: 5em;
}

.star-rating:before {
    content: "\e021\e021\e021\e021\e021";
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    color: #999;
}

.star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
}

.star-rating span:before {
    content: "\e020\e020\e020\e020\e020";
    top: 0;
    position: absolute;
    left: 0;
    color: #F90;
}
/* rating block specific to single product summary area */
.woocommerce-product-rating {
    display: block;
    width: 100%;
}

.woocommerce-product-rating .star-rating {
    margin: 0 auto;
    float: left;
    font-size: 1em;
}

.woocommerce-product-rating .woocommerce-review-link {
    font-size: 85%;
    width: 100%;
    margin: 0.5em 0;
    float: left;
}
/* rating block specific to review submit form */
.comment-form-rating p.stars {
    position: relative;
    padding: 0.75em;
}

.comment-form-rating p.stars a {
    display: inline-block;
    margin-right: 1em;
    text-indent: -9999px;
    position: relative;
    border-bottom: 0!important;
    outline: 0;
    color: #999;
}

.comment-form-rating p.stars a.active,
.comment-form-rating p.stars a:hover {
    color: #F90;
}

.comment-form-rating p.stars a:last-child {
    border-right: 0;
}

.comment-form-rating p.stars [class^="star-"],
.comment-form-rating p.stars [class*=" star-"] {
    border-right: 1px solid #ccc;
}

.comment-form-rating p.stars [class^="star-"]:after,
.comment-form-rating p.stars [class*=" star-"]:after {
    text-indent: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.comment-form-rating p.stars a.star-1 {
    width: 2em;
}

.comment-form-rating p.stars a.star-1:after {
    content: "\e021";
}

.comment-form-rating p.stars a.star-1.active:after,
.comment-form-rating p.stars a.star-1:hover:after {
    content: "\e020";
}

.comment-form-rating p.stars a.star-2 {
    width: 3em;
}

.comment-form-rating p.stars a.star-2:after {
    content: "\e021\e021";
}

.comment-form-rating p.stars a.star-2.active:after,
.comment-form-rating p.stars a.star-2:hover:after {
    content: "\e020\e020";
}

.comment-form-rating p.stars a.star-3 {
    width: 4em;
}

.comment-form-rating p.stars a.star-3:after {
    content: "\e021\e021\e021";
}

.comment-form-rating p.stars a.star-3.active:after,
.comment-form-rating p.stars a.star-3:hover:after {
    content: "\e020\e020\e020";
}

.comment-form-rating p.stars a.star-4 {
    width: 5em;
}

.comment-form-rating p.stars a.star-4:after {
    content: "\e021\e021\e021\e021";
}

.comment-form-rating p.stars a.star-4.active:after,
.comment-form-rating p.stars a.star-4:hover:after {
    content: "\e020\e020\e020\e020";
}

.comment-form-rating p.stars a.star-5 {
    width: 6em;
}

.comment-form-rating p.stars a.star-5:after {
    content: "\e021\e021\e021\e021\e021";
}

.comment-form-rating p.stars a.star-5.active:after,
.comment-form-rating p.stars a.star-5:hover:after {
    content: "\e020\e020\e020\e020\e020";
}
/* rating block specific to product listing */
.comment-form-rating .star-rating {
    display: block;
    text-align: center;
    margin: 0 auto;
}
/* rating block specific to sidebar widgets */
.comment-form-rating .star-rating,
.comment-form-rating .star-rating {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.woocommerce-variation-price {
    margin-bottom: 15px;
}
/* Variation as buttons */

.variations {
    display: none !important;
}

.variation-outer-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 30px;
}

.variation-outer-wrapper h3 {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
}

.variation-outer-wrapper h4 {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    display: block;
}

.product-variation-wrapper {
    max-width: 30%;
    width: auto;
    margin-right: 10px;
    border-radius: 5px;
    background: color(light_green);
    border: 3px solid color(dark_green);
    cursor: pointer;
    padding: 7px 10px 5px;
    color: #000;
}

.product-variation-wrapper p {
    color: #000;
    margin: 0;
    padding: 0;
    font-weight: bold;

    &:hover {
        color: #fff;
    }
}

.product-variation-wrapper:hover {
    transition: 0.2s ease-in-out;
    background: color(dark_green);
    color: #fff;

    p {
        color: #fff;
    }
}

.active-variation {
    background: color(dark_green);
    color: #fff;

    p {
        color: #fff;
    }
}
/* cart and checkout*/

.woocommerce {
    padding-top: 90px;
}

.woocommerce-cart-form__cart-item {
    .product-name {
        a {
            color: color(orange)!important;
            text-decoration: none!important;
        }
    }
}

.wc-proceed-to-checkout {
    a {
        color: #fff!important;
        background-color: color(dark_green)!important;
        padding: 10px 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.coupon button {
    color: #000!important;
    background-color: color(yellow)!important;
}

#coupon_code {
    min-width: 120px!important;
}

.woocommerce-checkout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.woocommerce-billing-fields {
    width: 100%;
}

#customer_details {
    width: 53%;

    .col-1 {
        width: 100%;
    }

    .col-2 {
        width: 100%;
    }

    input {
        border: 1px solid #ccc;
        padding: 8px 5px;
    }
}

#order_review {
    width: 45%;
}

#order_review_heading {
    display: none;
}

#place_order {
    color: #fff!important;
    background-color: color(dark_green)!important;
}

.woocommerce-checkout #payment {
    background: color(light_green)!important;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
    background: color(mint)!important;
}

.woocommerce-NoticeGroup-checkout {
    width: 100%;
}
/* my account */

.trinxy-my-account {
    min-height: 80vh;

}
.my-account-nav {
    background: color(light_green)!important;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 50vh;
padding: 0;
}
.woocommerce-MyAccount-navigation {
    position: relative;

    ul {

        margin: 0;
        padding: 0;
        list-style: none;
        height: auto;


        li {
            padding: 10px 30px;
            a {
color: #000;
}
&:hover {
    background: color(dark_green)!important;
    a {
color: #fff;
}
transition: 0.2s ease-in-out;
}
        }

        .is-active {
            background: color(dark_green)!important;
            a {
color: #fff;
}
        }
    }
}
.woocommerce-Address-title {
height: auto;
padding:0;
background: transparent;
}
.woocommerce-address-fields {
input {
padding: 5px;
}
}
.woocommerce-MyAccount-content {
    min-height: 50vh;
    padding: 30px;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
